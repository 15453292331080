<template></head>
  <div id="app">
    <v-app>
      <v-main>
        <confirm ref="confirm" top></confirm>
        <!--         <div v-if="showMenu === true">
 -->
        <div v-if="showMen() === 'ok'">
          <v-app-bar fixed position="absolute" :color="this.$menuColor" dark height="50" width="100%" class="appBar">
            <v-row justify="center" align="center">
              <v-col cols="1">
                <v-btn fixed x-small height="0" color="white--text" @click="drawer = !drawer">
                  Menu
                </v-btn>
                <v-app-bar-nav-icon @click="drawer = !drawer">
                </v-app-bar-nav-icon>
                <!--                   @mouseup="drawer = !drawer"
 -->
              </v-col>
              <v-col>
                <h4>Rides Chair Dashboard: {{ title }}</h4>
              </v-col>
            </v-row>
          </v-app-bar>

          <v-progress-linear :v-model="waitActive" :indeterminate="waitActive ? true : false" height="70" full-width
            :color="this.$waitColor" :background-color="this.$menuColor">
          </v-progress-linear>

          <v-overlay v-model="waitActive">
            <!--         <body translate="no">
              <div class="canvas">
                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="ninth"></div>

                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="ninth">
                  <div class="cornersWrapper">
                    <div class="corner is-large"></div>
                    <div class="corner is-medium"></div>
                    <div class="corner is-small"></div>
                  </div>
                </div>

                <div class="circle is-first"></div>
                <div class="circle is-second"></div>
                <div class="circle is-third"></div>
                <div class="circle is-fourth"></div>
                <div class="circle is-fifth"></div>
                <div class="circle is-sixth"></div>
                <div class="circle is-seventh"></div>
                <div class="circle is-eighth"></div>

                <div class="meetingPoint"></div>
              </div>

            </body>

-->
            <!-- 

            <v-img class="waitImage" src="https://test.ebcrides.org/images/bluffingtonRavineRec.gif"
              :v-model="waitActive">
            </v-img>
 -->

            <v-img class="waitImage" src="https://ebcrides.org/images/bikingInTheWoods.gif" :v-model="waitActive">


            </v-img>
            <v-row justify="center" align="center" class="wait">
              Please Wait...

            </v-row>
            </v-card>
          </v-overlay>

          <v-navigation-drawer v-model="drawer" fixed temporary :color="this.$menuColor" dark>
            <v-list>
              <v-list-item @click="noCache('/RideStatus')">Ride Leader Status</v-list-item>
              <v-list-item @click="noCache('/List')">Ride List</v-list-item>
              <v-list-item @click="noCache('/Browser')">Image Browser</v-list-item>
              <v-list-item @click="noCache('/msgEditor/newYear.msg/New Year Message/')">
                Edit eMail Messages
              </v-list-item>
              <v-list-item @click="noCache('/newYearReset/False/')">
                New Year Reset
              </v-list-item>
              <v-list-item @click="noCache('/RideLeaders')">Email Address Book</v-list-item>
              <v-list-item @click="noCache('/RideReview/Joanne%20Davis/all')">
                Ride Leaders Review Sample
              </v-list-item>

              <v-list-item @click="noCache('/About')"> About
              </v-list-item>
              <v-list-item @click="logout()">Logout <br />
                &nbsp; (will clear saved password)</v-list-item>
            </v-list>
          </v-navigation-drawer>
        </div>

        <router-view>
        </router-view>

      </v-main>
    </v-app>
  </div>
</template>

<script>
// const server = "http:/192.168.1.12/ebc.ebcrides.org/";

import EventBus from "./event-bus";
import confirm from "./components/Confirm";
import mixins from "./components/mixins";
import Vue from "vue";
import moment from "moment";
import router from "./router";
export default {
  name: "App",
  components: {
    confirm,
  },
  mixins: [mixins],
  data: () => ({
    title: "",
    drawer: false,
    //    showRideReview: false,
    auth: false,
    waitActive: true,
    showMenu: true,
  }),

  methods: {
    showMen() {
      var ret = sessionStorage.getItem("calendarPWD");
      return ret;
    },
    logout() {
      sessionStorage.setItem("calendar", "");
      localStorage.setItem("calendar", "");


      this.drawer = false;
      this.noCache("/");
    },
  },
  created() {
    process.env.VUE_APP_ROOT_API;

    /* 
        Vue.config.errorHandler = (err, vm, info) => {
          // vm: component in which error occured
          // info: Vue specific error information such as lifecycle hooks, events etc.
    
          //  x[2] = {
          //    agreeButtonText: "Done",
          //    oneButton: true,
          //  };
          alert("error: " + err + "\nInfo: " + info + "\n");
        };
    
     */    // TODO: Perform any custom logic or log to server


    EventBus.$on("appError", (x) => {
      if (x[2] == undefined) {
        x[2] = {
          agreeButtonText: "OK",
          oneButton: "hide",
          color: "red white--text",
        };
      }
      if (x[0] === "" || x[0] === null) {
        x[0] = "Error";
      }
      x[1] += "\n\n";
      //     x[1] += "\n--------------------------------------------------------------------------------------------";
      //     alert(x[0] + "\n\n" + x[1]);
      //      alert(x[0] + x[1]);
      //      this.$root.$confirm = 
      // this.processError(x).then((confirm) => {
      //   alert("Error: " + err + "n ")
      this.$refs.confirm.open(x[0], x[1], x[2]);
      //alert("Error: " + x[0] + " msg: " + x[1]);
    });

    EventBus.$on("title", (x) => {
      this.title = x;
    });

    EventBus.$on("wait", (x) => {
      if (x === true) {
        this.waitActive = true;
      }
      else if (x === false) {
        this.waitActive = false;
      }
    });
  },
};
</script>
<!--  .v-overlay__scrim {
  background: url(https:/ebcrides.org/images/cal/bg.jpg) no-repeat center center
    fixed;
 -->

<style scoped>
.waitImage {
  color: #0ff;
  opacity: 70%;
}

.wait {
  /*  color: #1a12b5; */
  color: #0ff;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 33px;
  padding-top: 3px;
}

.dialog {
  background-image: url(https://media.giphy.com/media/pZQE3FFvBBhvO/giphy.gif);
  opacity: 0.6;
  font-size: 60;
}

.appBar {
  padding: 10px;
}
</style>
<style>
html * {
  font-size: 1 rem;
}

h1 {
  font-size: 1.5rem !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

h2 {
  font-size: 1.3rem !important;
}

h3 * {
  font-size: 1.1rem !important;
  /*  color: #000 !important;*/
}

h4 {
  font-size: 1.4rem !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

*.row-height td {
  height: 34px !important;
  font-size: 0.8rem !important;
}

button * {
  font-size: 0.6rem !important;
  font-weight: bold;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.sub-heading {
  font-size: 11px;
}


:root {
  --background-color: #1b1b2c;
  --main-color: #0ff;
  --meeting-point-color: #7fffff;

  --canvas-size: 68vmin;
  --canvas-max-size: 500px;

  --circle-size: 8vmin;

  --corner-size-large: 20vmin;
  --corner-size-medium: 16vmin;
  --corner-size-small: 12vmin;

  --corner-side-size-width: 1.5vmin;
  --corner-side-size-height: 1.5vmin;

  --animation-duration: 3000ms;
}


.canvas {
  width: var(--canvas-size);
  height: var(--canvas-size);

  max-width: var(--canvas-max-size);
  max-height: var(--canvas-max-size);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  position: relative;
}

.ninth {
  position: relative;
  overflow: hidden;
}

.cornersWrapper {
  position: absolute;
  z-index: 1;
  mix-blend-mode: plus-lighter;
}

.ninth:nth-child(1) .cornersWrapper {
  bottom: 0;
  right: 0;
}

.ninth:nth-child(2) .cornersWrapper {
  bottom: 0;
  left: 50%;
  transform: rotate(45deg);
}

.ninth:nth-child(3) .cornersWrapper {
  bottom: 0;
  transform: rotate(90deg);
}

.ninth:nth-child(4) .cornersWrapper {
  top: 50%;
  right: 0;
  transform: rotate(315deg);
}

.ninth:nth-child(6) .cornersWrapper {
  top: 50%;
  transform: rotate(135deg);
}

.ninth:nth-child(7) .cornersWrapper {
  right: 0;
  transform: rotate(270deg);
}

.ninth:nth-child(8) .cornersWrapper {
  left: 50%;
  transform: rotate(225deg);
}

.ninth:nth-child(9) .cornersWrapper {
  transform: rotate(180deg);
}

.corner {
  position: absolute;
}

.corner.is-large {
  width: var(--corner-size-large);
  height: var(--corner-size-large);
  bottom: 0;
  right: 0;
}

.corner.is-medium {
  width: var(--corner-size-medium);
  height: var(--corner-size-medium);
  opacity: 0.35;
  bottom: 4vmin;
  right: 4vmin;
}

.corner.is-small {
  width: var(--corner-size-small);
  height: var(--corner-size-small);
  bottom: 8vmin;
  right: 8vmin;
}

.corner::before,
.corner::after {
  content: "";
  position: absolute;
}

.corner::before {
  width: var(--corner-side-size-width);
  height: 100%;
  right: 0;
  background: linear-gradient(transparent, var(--main-color) 50%);
}

.corner::after {
  width: 100%;
  height: var(--corner-side-size-height);
  bottom: 0;
  background: linear-gradient(90deg, transparent, var(--main-color) 50%);
}

.circle {
  position: absolute;
  width: var(--circle-size);
  height: var(--circle-size);
  background-color: var(--main-color);
  border-radius: 50%;
  box-shadow: 0 0 20px var(--main-color);
}

@keyframes move-circle-first {

  0%,
  100% {
    top: 0;
    left: 0;
    transform: scale(0) translate(0%, 0%);
  }

  20% {
    top: 0;
    left: 0;
    transform: scale(1) translate(0%, 0%);
    opacity: 1;
  }

  85% {
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
    opacity: 0.75;
  }

  95% {
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}

.circle.is-first {
  top: 0;
  left: 0;
  transform: scale(0) translate(0%, 0%);
  animation: move-circle-first var(--animation-duration) ease-in-out infinite;
}

@keyframes move-circle-second {

  0%,
  100% {
    top: calc(-1 * var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(-50%, -80%);
  }

  20% {
    top: 0;
    left: 50%;
    transform: scale(1) translate(-50%, -80%);
    opacity: 1;
  }

  85% {
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
    opacity: 0.75;
  }

  95% {
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}

.circle.is-second {
  top: 0;
  left: 50%;
  transform: scale(0) translate(-50%, -80%);
  animation: move-circle-second var(--animation-duration) ease-in-out infinite;
  animation-delay: calc(var(--animation-duration) * 0.4);
}

@keyframes move-circle-third {

  0%,
  100% {
    top: 0;
    right: 0;
    transform: scale(0) translate(0%, 0%);
  }

  20% {
    top: 0;
    right: 0;
    transform: scale(1) translate(0%, 0%);
    opacity: 1;
  }

  85% {
    top: 50%;
    right: 50%;
    transform: scale(1) translate(50%, -50%);
    opacity: 0.75;
  }

  95% {
    top: calc(50% - var(--circle-size) / 2);
    right: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

.circle.is-third {
  top: 0;
  right: 0;
  transform: scale(0) translate(0%, 0%);
  animation: move-circle-third var(--animation-duration) ease-in-out infinite;
}

@keyframes move-circle-fourth {

  0%,
  100% {
    top: calc(50% - var(--circle-size) / 2);
    left: calc(-1 * var(--circle-size) / 2);
    transform: scale(0) translate(-80%, -50%);
  }

  20% {
    top: 50%;
    left: 0;
    transform: scale(1) translate(-80%, -50%);
    opacity: 1;
  }

  85% {
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
    opacity: 0.75;
  }

  95% {
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}

.circle.is-fourth {
  top: 50%;
  left: 0;
  transform: scale(0) translate(-80%, -50%);
  animation: move-circle-fourth var(--animation-duration) ease-in-out infinite;
  animation-delay: calc(var(--animation-duration) * 0.4);
}

@keyframes move-circle-fifth {

  0%,
  100% {
    top: calc(50% - var(--circle-size) / 2);
    right: calc(-1 * var(--circle-size) / 2);
    transform: scale(0) translate(80%, -50%);
  }

  20% {
    top: 50%;
    right: 0;
    transform: scale(1) translate(80%, -50%);
    opacity: 1;
  }

  85% {
    top: 50%;
    right: 50%;
    transform: scale(1) translate(50%, -50%);
    opacity: 0.75;
  }

  95% {
    top: calc(50% - var(--circle-size) / 2);
    right: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

.circle.is-fifth {
  top: 50%;
  right: 0;
  transform: scale(0) translate(80%, -50%);
  animation: move-circle-fifth var(--animation-duration) ease-in-out infinite;
  animation-delay: calc(var(--animation-duration) * 0.4);
}

@keyframes move-circle-sixth {

  0%,
  100% {
    bottom: 0;
    left: 0;
    transform: scale(0) translate(0%, 0%);
  }

  20% {
    bottom: 0;
    left: 0;
    transform: scale(1) translate(0%, 0%);
    opacity: 1;
  }

  85% {
    bottom: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, 50%);
    opacity: 0.75;
  }

  95% {
    bottom: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(-50%, 50%);
    opacity: 0;
  }
}

.circle.is-sixth {
  bottom: 0;
  left: 0;
  transform: scale(0) translate(0%, 0%);
  animation: move-circle-sixth var(--animation-duration) ease-in-out infinite;
}

@keyframes move-circle-seventh {

  0%,
  100% {
    bottom: calc(-1 * var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(-50%, 80%);
  }

  20% {
    bottom: 0;
    left: 50%;
    transform: scale(1) translate(-50%, 80%);
    opacity: 1;
  }

  85% {
    bottom: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, 50%);
    opacity: 0.75;
  }

  95% {
    bottom: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(-50%, 50%);
    opacity: 0;
  }
}

.circle.is-seventh {
  bottom: 0;
  left: 50%;
  transform: scale(0) translate(-50%, 80%);
  animation: move-circle-seventh var(--animation-duration) ease-in-out infinite;
  animation-delay: calc(var(--animation-duration) * 0.4);
}

@keyframes move-circle-eighth {

  0%,
  100% {
    bottom: 0;
    right: 0;
    transform: scale(0) translate(0%, 0%);
  }

  20% {
    bottom: 0;
    right: 0;
    transform: scale(1) translate(0%, 0%);
    opacity: 1;
  }

  85% {
    bottom: 50%;
    right: 50%;
    transform: scale(1) translate(50%, 50%);
    opacity: 0.75;
  }

  95% {
    bottom: calc(50% - var(--circle-size) / 2);
    right: calc(50% - var(--circle-size) / 2);
    transform: scale(0) translate(50%, 50%);
    opacity: 0;
  }
}

.circle.is-eighth {
  bottom: 0;
  right: 0;
  transform: scale(0) translate(0%, 0%);
  animation: move-circle-eighth var(--animation-duration) ease-in-out infinite;
}

.meetingPoint {
  width: 12vmin;
  height: 12vmin;
  border-radius: 50%;
  border: solid 1.5vmin var(--meeting-point-color);
  filter: drop-shadow(0 0 30px var(--main-color));
  mix-blend-mode: plus-lighter;
  position: absolute;
  inset: 50%;
  transform: translate(-50%, -50%);
}
</style>
