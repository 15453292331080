import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCol,[_c(VDialog,{attrs:{"width":_vm.options.width,"transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"box",class:_vm.options.color},[_c(VDivider),_c(VDivider),_c(VCol,[_c(VRow,{attrs:{"justify":"space-between"}},[_c('b',[_vm._v(_vm._s(_vm.title)+" ")])])],1),_c(VDivider),_c(VDivider),_c(VCol,[_c(VCard,{staticClass:"text-pre-wrap wrap-text",attrs:{"color":"#c8efff"}},_vm._l((this.$store.state.message),function(msg){return _c(VCol,{key:"msg"},[_vm._v(" "+_vm._s(msg)+" ")])}),1),_c(VRow,{attrs:{"justify":"space-between"}},[_c(VBtn,{staticClass:"btn",class:_vm.options.agreeButtonColor,attrs:{"rounded":"","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}},model:{value:(_vm.options.agreeButton),callback:function ($$v) {_vm.$set(_vm.options, "agreeButton", $$v)},expression:"options.agreeButton"}},[_vm._v(" "+_vm._s(_vm.options.agreeButtonText)+" ")]),(_vm.options.oneButton === false)?_c('div',[_c(VBtn,{staticClass:"red lighten-1 white--text btn",attrs:{"color":"black","rounded":"","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.options.oneButton),callback:function ($$v) {_vm.$set(_vm.options, "oneButton", $$v)},expression:"options.oneButton"}},[_vm._v(" "+_vm._s(_vm.options.cancelButtonText)+" ")])],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }