<template>
  <v-col>
    <v-dialog v-model="dialog" :width="options.width" transition="dialog-bottom-transition" persistent>
      <v-card :class="options.color" class="box">
        <!--         <v-card outline class="wrap text text-h6 box" :class="options.color">
 -->

        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-col>
          <v-row justify="space-between">
            <b>{{ title }} </b>
          </v-row>
        </v-col>

        <v-divider></v-divider>
        <v-divider></v-divider>

        <v-col>
          <v-card class="text-pre-wrap  wrap-text" color="#c8efff">
            <v-col v-for="msg in this.$store.state.message" key="msg">
              {{ msg }}


            </v-col>
          </v-card>

          <v-row justify="space-between">
            <v-btn v-model="options.agreeButton" rounded :class="options.agreeButtonColor" class="btn" text
              @click.native="agree">
              {{ options.agreeButtonText }}
            </v-btn>
            <div v-if="options.oneButton === false">
              <v-btn v-model="options.oneButton" class="red lighten-1 white--text btn" color="black" rounded text
                @click.native="cancel">
                {{ options.cancelButtonText }}
              </v-btn>
            </div>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
//  * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
//  */
import EventBus from "../event-bus";


export default {
  data: () => ({
    queue: false,
    dialog: false,
    resolve: null,
    reject: null,
    message: "",
    title: null,
    options: {
      color: "green lighten-2",
      width: 425,
      // zIndex: 200,
      oneButton: false,
      agreeButtonText: "Yes",
      agreeButtonColor: "green darken- 2 white--text",
      cancelButtonText: "Cancel",
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.options = Object.assign(this.options, options);
      /* if (this.$store.state.message.length > 0) {
        this.$store.state.message.push(message);
      }
      else {
       */
      //      this.queue = false;
      //  this.pp(this.options);
      var len = this.$store.state.message.length;
      this.$store.state.message[len] = message;

      if (len === 0) {
        this.title = title;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      }
      else return false;
      // }
      //  },
      //  return true;
    },
    agree() {
      this.resolve(true);
      // this.message = "";
      this.dialog = false;
      this.$store.state.message = [];
      EventBus.$emit("wait", "false");
    },
    cancel() {
      this.$store.state.message = [];
      this.resolve(false);
      this.queue = false;
      this.dialog = false;
      EventBus.$emit("wait", "false");
    },
    pp(object) {
      return JSON.stringify(object, 4, "- ");
    },
  },
};
</script>
<style>
.box {
  padding: 5px;
  padding-right: 20px;


  /*  padding-left: 25px;
  padding-right: 25px; */
}

.btn {
  padding-right: 30px;
  padding-top: 2px;
}

.popup2 {
  color: black;
  background-color: antiquewhite;
  padding: 100;
  border: 2px solid gray;

}
</style>
//
