<template>
  <div id="Root">
    <!--     <v-img src="https://ebcrides.org/images/lake%20bluff.jpg" contain>
      <v-container float fill-height></v-container>
    </v-img>
 -->
  </div>
</template>

<script>
import "vuetify/dist/vuetify.min.css";
//mport CryptoJS from "crypto-js";
import mixins from "../components/mixins";
//import axios from "axios";
export default {
  name: "Root",
  /*   props: {
    sourceString,
  },
 */
  mixins: [mixins],
  data() {
    return {};
  },
  async created() {
    var encrypted = localStorage.getItem("calendar");
    if (encrypted === undefined || encrypted === "") {
      //    console.log(password);
      // sessionStorage.setItem("calendar", "");
      this.noCache("/Login");
    } else {
      //copy persistant password to session passsowrd
      //      const bytes = CryptoJS.AES.decrypt(encrypted, this.encriptkey);
      //      var password = bytes.toString(CryptoJS.enc.Utf8);
      sessionStorage.setItem("calendar", encrypted);
      var ret = await this.checkPassword(encrypted, "encrypted"); console.log("check passord from root is: ", ret);
      if (ret === "login") this.noCache("/Login");
      else if (ret === "error") {
        this.handleError("Recieved an error trying to validate password. Stopping, can't continue.");

      }
      //      else this.$router.push("/RideStatus");
      else this.noCache("/RideStatus");
    }
  },
  methods: {},
};
</script>

<style></style>
